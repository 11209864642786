export default [
    {
        path: '/design-system',
        component: () => import('../components/design_system/Index.vue'),
      children:[
        {
          path: '',
          name: 'DesignSystemIndex',
          component: () => import('../components/design_system/DesignSystem.vue')
        },
        {
          path: 'accordions',
          name: 'DesignSystemAccodions',
          component: () => import('../components/design_system/Accordions.vue')
        },
        {
          path: 'blade-headers',
          name: 'DesignSystemBladeHeaders',
          component: () => import('../components/design_system/BladeHeaders.vue')
        },
        {
          path: 'bottom-action-bar',
          name: 'DesignSystemBottomActionBar',
          component: () => import('../components/design_system/BottomActionBar.vue')
        },
        {
          path: 'breadcrumbs',
          name: 'DesignSystemBreadcrumbs',
          component: () => import('../components/design_system/Breadcrumbs.vue')
        },
        {
          path: 'buttons',
          name: 'DesignSystemButtons',
          component: () => import('../components/design_system/Buttons.vue')
        },
        {
          path: 'checkboxes',
          name: 'DesignSystemCheckboxes',
          component: () => import('../components/design_system/Checkboxes.vue')
        },
        {
          path: 'chips',
          name: 'DesignSystemChips',
          component: () => import('../components/design_system/Chips.vue')
        },
        {
          path: 'color-system',
          name: 'DesignSystemColorSystem',
          component: () => import('../components/design_system/ColorSystem.vue')
        },
        {
          path: 'communications',
          name: 'DesignSystemCommunications',
          component: () => import('../components/design_system/Communications.vue')
        },
        {
          path: 'comboboxes',
          name: 'DesignSystemComboboxes',
          component: () => import('../components/design_system/Comboboxes.vue')
        },
        {
          path: 'component-registration',
          name: 'DesignSystemComponentRegistration',
          component: () => import('../components/design_system/ComponentRegistration.vue')
        },
        {
          path: 'component-usage',
          name: 'DesignSystemComponentUsage',
          component: () => import('../components/design_system/ComponentUsage.vue')
        },
        {
          path: 'elevations',
          name: 'DesignSystemElevations',
          component: () => import('../components/design_system/Elevations.vue')
        },
        {
          path: 'empty-states',
          name: 'DesignSystemEmptyStates',
          component: () => import('../components/design_system/EmptyStates.vue')
        },
        {
          path: 'file-inputs',
          name: 'DesignSystemFileInputs',
          component: () => import('../components/design_system/FileInputs.vue')
        },
        {
          path: 'forms',
          name: 'DesignSystemForms',
          component: () => import('../components/design_system/Forms.vue')
        },
        {
          path: 'global-css',
          name: 'DesignSystemCss',
          component: () => import('../components/design_system/GlobalCss.vue')
        },
        {
          path: 'global-notifications',
          name: 'DesignSystemGlobalNotifications',
          component: () => import('../components/design_system/GlobalNotifications.vue')
        },
        {
          path: 'header-info',
          name: 'DesignSystemHeaderInfo',
          component: () => import('../components/design_system/HeaderInfo.vue')
        },
        {
          path: 'headers',
          name: 'DesignSystemHeaders',
          component: () => import('../components/design_system/Headers.vue')
        },
        {
          path: 'icons',
          name: 'DesignSystemIcons',
          component: () => import('../components/design_system/Icons.vue')
        },
        {
          path: 'info-cards',
          name: 'DesignSystemInfoCards',
          component: () => import('../components/design_system/InfoCards.vue')
        },
        {
          path: 'inputs',
          name: 'DesignSystemInputs',
          component: () => import('../components/design_system/Inputs.vue')
        },
        {
          path: 'links',
          name: 'DesignSystemLinks',
          component: () => import('../components/design_system/Links.vue')
        },
        {
          path: 'media-viewers',
          name: 'DesignSystemMediaViewers',
          component: () => import('../components/design_system/MediaViewers.vue')
        },
        {
          path: 'menus',
          name: 'DesignSystemMenus',
          component: () => import('../components/design_system/Menus.vue')
        },
        {
          path: 'mini-profiles',
          name: 'DesignSystemMiniProfiles',
          component: () => import('../components/design_system/MiniProfiles.vue')
        },
        {
          path: 'modals',
          name: 'DesignSystemModals',
          component: () => import('../components/design_system/Modals.vue')
        },
        {
          path: 'notifications',
          name: 'DesignSystemNotifications',
          component: () => import('../components/design_system/Notifications.vue')
        },
        {
          path: 'page-menus',
          name: 'DesignSystemPageMenus',
          component: () => import('../components/design_system/PageMenus.vue')
        },
        {
          path: 'property-selector',
          name: 'DesignSystemPropertySelector',
          component: () => import('../components/design_system/PropertySelector.vue')
        },
        {
          path: 'radios',
          name: 'DesignSystemRadios',
          component: () => import('../components/design_system/Radios.vue')
        },
        {
          path: 'spacing',
          name: 'DesignSystemSpacing',
          component: () => import('../components/design_system/Spacing.vue')
        },
        {
          path: 'space-icons',
          name: 'DesignSystemSpaceIcons',
          component: () => import('../components/design_system/SpaceIcons.vue')
        },
        {
          path: 'statuses',
          name: 'DesignSystemStatuses',
          component: () => import('../components/design_system/Statuses.vue')
        },
        {
          path: 'statuses-general',
          name: 'DesignSystemStatusesGeneral',
          component: () => import('../components/design_system/StatusesGeneral.vue')
        },
        {
          path: 'steppers',
          name: 'DesignSystemSteppers',
          component: () => import('../components/design_system/Steppers.vue')
        },
        {
          path: 'switches',
          name: 'DesignSystemSwitches',
          component: () => import('../components/design_system/Switches.vue')
        },
        {
          path: 'tables',
          name: 'DesignSystemTables',
          component: () => import('../components/design_system/Tables.vue')
        },
        {
          path: 'tabs',
          name: 'DesignSystemTabs',
          component: () => import('../components/design_system/Tabs.vue')
        },
        {
          path: 'tasks',
          name: 'DesignSystemTasks',
          component: () => import('../components/design_system/Tasks.vue')
        },
        {
          path: 'timelines',
          name: 'DesignSystemTimelines',
          component: () => import('../components/design_system/Timelines.vue'),
        },
        {
          path: 'toasts',
          name: 'DesignSystemToasts',
          component: () => import('../components/design_system/Toasts.vue')
        },
        {
          path: 'tooltips',
          name: 'DesignSystemTooltips',
          component: () => import('../components/design_system/Tooltips.vue')
        },
        {
          path: 'typography',
          name: 'DesignSystemTypography',
          component: () => import('../components/design_system/Typography.vue')
        },
        {
          path: 'unit-icons',
          name: 'DesignSystemUnitIcons',
          component: () => import('../components/design_system/UnitIcons.vue')
        },
      ]
    }
  ]