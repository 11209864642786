export default [{
  name: "Close Of Day",
  path: '/close-of-day',
  component: () => import('../components/close_of_day/Index.vue'),
  meta: {
    requiresAuth: true,
    hasAccess: ['admin'],
    layout: 'master',
    re_fetch:true
  }
}]
