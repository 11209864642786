<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="hb-status-general-wrapper" :class="chip && time ? 'hb-status-general-chip-time' : chip ? 'hb-status-general-chip' : ''" :style="chip ? 'background-color:' + computedChipBackgroundColor : ''">

        <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
            <template v-slot:item>
                <div class="d-flex align-center">
                    <hb-icon
                        v-if="!iconOff"
                        small
                        :color="iconColor"
                        :mdi-code="iconCode"
                        class="hb-status-general-icon"
                        :class="{ 'hb-status-general-icon-position-fix' : type == 'tenant' || statusText === 'Tenant™' || statusText === 'Tenant™ Process' || statusText === 'Tenant™ Template', 'pr-1' : $slots.default || statusText }"
                    >
                    </hb-icon>

                    <div :class="statusColor" v-if="$slots.default">
                        <slot></slot>
                    </div>
                    <div :class="statusColor" v-else-if="!preview">
                        {{ statusText }}
                    </div>
                </div>
            </template>
            <template v-slot:header>
                <span v-if="$slots.tooltipHeader">
                    <slot name="tooltipHeader"></slot>
                </span>
                <span v-else-if="tooltipHeader">
                    {{ tooltipHeader }}
                </span>
            </template>
            <template v-slot:body>
                <span v-if="$slots.tooltipBody">
                    <slot name="tooltipBody"></slot>
                </span>
                <span v-else-if="tooltipBody">
                    {{ tooltipBody }}
                </span>
            </template>
        </hb-tooltip>

        <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
            <template v-slot:item>
                <div class="d-flex align-center">
                    <hb-icon
                        v-if="!iconOff"
                        small
                        :color="iconColor"
                        :mdi-code="iconCode"
                        class="hb-status-general-icon"
                        :class="{ 'hb-status-general-icon-position-fix' : type == 'tenant' || statusText === 'Tenant™' || statusText === 'Tenant™ Process' || statusText === 'Tenant™ Template', 'pr-1' : $slots.default || statusText }"
                    >
                    </hb-icon>

                    <div :class="statusColor" v-if="$slots.default">
                        <slot></slot>
                    </div>
                    <div :class="statusColor" v-else-if="!preview">
                        {{ statusText }}
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <span v-if="$slots.tooltipBody">
                    <slot name="tooltipBody"></slot>
                </span>
                <span v-else-if="tooltipBody">
                    {{ tooltipBody }}
                </span>
            </template>
        </hb-tooltip>

        <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
            <template v-slot:item>
                <div class="d-flex align-center">
                    <hb-icon
                        v-if="!iconOff"
                        small
                        :color="iconColor"
                        :mdi-code="iconCode"
                        class="hb-status-general-icon"
                        :class="{ 'hb-status-general-icon-position-fix' : type == 'tenant' || statusText === 'Tenant™' || statusText === 'Tenant™ Process' || statusText === 'Tenant™ Template', 'pr-1' : $slots.default || statusText }"
                    >
                    </hb-icon>

                    <div :class="statusColor" v-if="$slots.default">
                        <slot></slot>
                    </div>
                    <div :class="statusColor" v-else-if="!preview">
                        {{ statusText }}
                    </div>
                </div>
            </template>
            <template v-slot:header>
                <span v-if="$slots.tooltipHeader">
                    <slot name="tooltipHeader"></slot>
                </span>
                <span v-else-if="tooltipHeader">
                    {{ tooltipHeader }}
                </span>
            </template>
        </hb-tooltip>

        <div
            v-else
            class="d-flex align-center"
        >
            <hb-icon
                v-if="!iconOff"
                small
                :color="iconColor"
                :mdi-code="iconCode"
                class="hb-status-general-icon"
                :class="{ 'hb-status-general-icon-position-fix' : type == 'tenant' || statusText === 'Tenant™' || statusText === 'Tenant™ Process' || statusText === 'Tenant™ Template', 'pr-1' : $slots.default || statusText }"
            >
            </hb-icon>

            <div :class="statusColor" v-if="$slots.default">
                <slot></slot>
            </div>
            <div :class="statusColor" v-else-if="!preview">
                {{ statusText }}
            </div>
        </div>
    </div>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbTooltip from './HbTooltip.vue';

    export default {
        name: "HbStatusGeneral",
        data: function() {
            return {
                types: [
                    /* default */
                    { icon_color: '#637381', name: 'Default', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#38afc6', name: 'Tenant™', icon_mdi_code: 'mdi-custom-tenant-logo', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#38afc6', name: 'Tenant™ Process', icon_mdi_code: 'mdi-custom-tenant-logo', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#38afc6', name: 'Tenant™ Template', icon_mdi_code: 'mdi-custom-tenant-logo', status_color: 'hb-status-general-text-night' },

                    /* Invoice Statuses */
                    { icon_color: '#637381', name: 'Void', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Write Off', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FB4C4C', name: 'Past Due', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'Open', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Paid', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    
                    /* Document Statuses */
                    { icon_color: '#FB4C4C', name: 'Bounced', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Error', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Failed', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'In Progress', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Print & Mail', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Ready to Sign', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Sent', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Signing in Progress', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Completed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Delivered', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Opened', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Signed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Success', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Generated', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Resolved', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Retried', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Uploaded', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },

                    /* Gate Statuses */
                    { icon_color: '#02AD0F', name: 'Active', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Suspended', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Access Alert', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FB4C4C', name: 'Manual Suspension', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Delinquency Suspension', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },

                    /* Misc Statuses */
                    { icon_color: '#637381', name: 'Deactivated',icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Deleted', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Offline', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Lease Closed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Retired Lead', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#637381', name: 'Retired', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FB4C4C', name: 'Active Lien', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Bankruptcy', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Overlocked', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Remove Overlock', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Schedule for Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Scheduled for Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'To Overlock', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'Active Lead', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Delinquent', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Future Leased', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Not Configured', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'On-Hold', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Pending', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Reserved', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Available', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Converted', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Converted Lead', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Current', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Charity', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Company', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Leased', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                ]
            };
        },
        components:{
            HbIcon,
            HbTooltip
        },
        computed: {
            selectedStatus(){
                if(this.statusText){
                    return this.types.filter( i => this.statusText.toLowerCase() === i.name.toLowerCase() );
                } else {
                    return '';
                }
            },
            iconColor(){
                var tempColor = '';

                if(this.type == 'success'){
                    tempColor = '#02AD0F';
                }
                else if(this.type == 'tenant'){
                    tempColor = '#38afc6';
                }
                else if(this.type == 'caution'){
                    tempColor = '#FFD600';
                }
                else if(this.type == 'error'){
                    tempColor = '#FB4C4C';
                }
                else if(this.type == 'guidance'){
                    tempColor = '#3D8FFF';
                }
                else if(this.selectedStatus.length){
                    tempColor = this.selectedStatus[0].icon_color;
                }
                else {
                    tempColor = '#637381';
                }

                this.$emit('emitColor', tempColor);
                return tempColor;
            },
            iconCode(){
                if(this.icon){
                    return this.icon;
                }
                else if(this.type == 'tenant'){
                    return 'mdi-custom-tenant-logo';
                }
                else if(this.type == 'success' || this.type == 'guidance'){
                    return 'mdi-check-circle';
                }
                else if(this.type == 'caution' && this.chip){
                    return 'mdi-minus-circle';
                }
                else if(this.type == 'caution'){
                    return 'mdi-alert';
                }
                else if(this.type == 'error' && this.chip){
                    return 'mdi-alert';
                }
                else if(this.type == 'error'){
                    return 'mdi-alert-circle';
                }
                else if(this.selectedStatus.length){
                    return this.selectedStatus[0].icon_mdi_code;
                }
                else if(this.chip){
                    return 'mdi-alert-octagon';
                }
                else {
                    return 'mdi-check-circle';
                }
            },
            statusColor(){
                if(this.selectedStatus.length){
                    return this.selectedStatus[0].status_color;
                }
                else if(this.type == 'success' && this.time){
                    return 'hb-status-general-text-success';
                }
                else if(this.type == 'caution' || this.type == 'success' || this.type == 'guidance' || this.type == 'tenant'){
                    return 'hb-status-general-text-night';
                }
                else if(this.type == 'error'){
                    return 'hb-status-general-text-error';
                } else {
                    return 'hb-status-general-text-night';
                }
            },
            statusText(){
                if(this.status){
                    let uppercaseFirstLetter = this.status.charAt(0).toUpperCase() + this.status.slice(1);
                    return uppercaseFirstLetter;
                } else if(this.$slots['default'] && this.$slots['default'][0] && this.$slots['default'][0].text && this.$slots['default'][0].text.length){
                    let string = this.$slots['default'][0].text.trim().toLowerCase();
                    let uppercaseFirstLetter = string.charAt(0).toUpperCase() + string.slice(1);
                    return uppercaseFirstLetter;
                } else {
                    return '';
                }
            },
            computedChipBackgroundColor(){

                if(this.chipBackgroundColor){
                    return this.chipBackgroundColor;
                }
                else if(this.type == 'success'){
                    return '#E1FAE3';
                }
                else if(this.type == 'caution'){
                    return '#FFF9DA';
                }
                else if(this.type == 'error'){
                    return '#F8E5E1';
                }
                else if(this.type == 'guidance'){
                    return '#E7F1FF';
                }
                else if(this.type == 'tenant'){
                    return '#E0F5F5';
                }
                else {
                    return '#F4F6F8';
                }
            },
        },
        props: {
            type: {
                type: String,
                default: undefined
            },
            icon: {
                type: String,
                default: undefined
            },
            tooltipHeader: {
                type: String,
                default: undefined
            },
            tooltipBody: {
                type: String,
                default: undefined
            },
            status: {
                type: String,
                default: undefined
            },
            preview: {
                type: Boolean,
                default: false
            },
            chip: {
                type: Boolean,
                default: false
            },
            chipBackgroundColor: {
                type: String,
                default: undefined
            },
            iconOff: {
                type: Boolean,
                default: false
            },
            time: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

.hb-status-general-wrapper {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    width: auto;
}

.hb-status-general-text-night {
    color: #101318;
}

.hb-status-general-text-error {
    color: #FB4C4C;
}

.hb-status-general-text-default {
    color: #637381;
}

.hb-status-general-icon {
    position: relative;
    top: -.5px;
}

.hb-status-general-icon-position-fix {
    position: relative;
    top: 2px;
}

.hb-status-general-text-success {
    color: #02ad0f;
}

.hb-status-general-chip {
    padding: 0px 5px 1px 4.5px;
    border-radius: 16px;
}

.hb-status-general-chip-time {
    padding: 0px 5px 1px 4.5px;
    border-radius: 4px;
}

</style>