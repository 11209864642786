var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.contact.id)?_c('hb-mini-profile',{attrs:{"type":_vm.contact.rent_as_business ? 'business' : 'contact',"title":_vm.contact?.first + ' ' + _vm.contact?.last,"representativeName":_vm.contact.rent_as_business ? _vm.computedRepresentativeName(_vm.contact) : '',"status":_vm.contact?.status,"military":_vm.contact?.Military?.active === 1,"phone":_vm.contact.Phones?.length > 0 ? _vm.formatPhoneNumber(_vm.contact?.Phones[0]?.phone) : '',"allow-phone-click":_vm.isOutBoundCallPermitted,"can-text":_vm.contact.Phones?.length > 0 ? !!_vm.contact.Phones[0]?.sms : '',"email":_vm.contact?.email,"address":_vm.contactAddressInfo(_vm.contact),"past-due-amount":_vm.contact.Leases && _vm.contact.Leases?.length > 0
        ? _vm.calPastDueAmount(_vm.contact?.Leases)
        : '',"is-lead":_vm.calTotalLeads(_vm.contact) === 1 && _vm.contact?.Leases?.length === 0 ? true : false,"lead-type":_vm.contact.Leads && _vm.contact.Leads?.length > 0 ? _vm.leadInfo(_vm.contact).type : '',"lead-space-type":_vm.leadInfo(_vm.contact).spaceType,"lead-space-number":_vm.leadInfo(_vm.contact).spaceNumber,"lead-space-category":_vm.leadInfo(_vm.contact).spaceLabel,"lead-featured-amenity":_vm.leadInfo(_vm.contact).spaceCategory,"lead-property-info":_vm.leadInfo(_vm.contact).spaceAddress || '',"use-menu":_vm.calTotalLeasesLength(_vm.contact) > 1 ? true : false,"contact-active-lead-count":_vm.calTotalLeads(_vm.contact),"contact-space-type":_vm.contact.Leases && _vm.contact.Leases?.length === 1
        ? _vm.contact?.Leases[0]?.Unit?.type
        : '',"contact-space-number":_vm.contact.Leases && _vm.contact.Leases?.length === 1
        ? _vm.contact?.Leases[0]?.Unit?.number
        : '',"contact-space-category":_vm.contact.Leases && _vm.contact.Leases?.length === 1
        ? _vm.contact?.Leases[0]?.Unit?.label
        : '',"contact-space-property-info":_vm.contact.Leases && _vm.contact.Leases?.length === 1
        ? _vm.contact.Leases[0]?.Unit?.property_number +
          ' - ' +
          _vm.spaceAddressInfo(_vm.contact.Leases[0]?.Unit?.Address)
        : '',"disable-contact-space-link":"","disable-lead-link":"","contact-space-access-code":_vm.accessAPIDone
        ? _vm.spaceAccessCode(
            _vm.contact?.Leases[0]?.Unit?.property_id,
            _vm.contact?.Leases[0]?.Unit?.id
          )?.pin
        : '',"contact-space-lockout":_vm.contact.Leases &&
      _vm.contact.Leases?.length === 1 &&
      (_vm.spaceAccessCode(
        _vm.contact?.Leases[0]?.Unit?.property_id,
        _vm.contact?.Leases[0]?.Unit?.id
      )?.hard_catch === 1 ||
        _vm.spaceAccessCode(
          _vm.contact?.Leases[0]?.Unit?.property_id,
          _vm.contact?.Leases[0]?.Unit?.id
        )?.late_catch === 1 ||
        _vm.spaceAccessCode(
          _vm.contact?.Leases[0]?.Unit?.property_id,
          _vm.contact?.Leases[0]?.Unit?.id
        )?.status === 0)
        ? true
        : false,"contact-space-count":_vm.calculateLength(_vm.contact.Leases, 'sameProperties'),"contact-space-count-other-properties":_vm.calculateLength(_vm.contact.Leases, 'otherProperties') ?? 0},on:{"phone-call-click":_vm.onMakePhoneCall,"phone-text-click":_vm.openText},scopedSlots:_vm._u([(_vm.contact.Leases && _vm.contact.Leases?.length > 0)?{key:"reportTooltipBody",fn:function(){return [_vm._v(" Total Balance: "+_vm._s(_vm.calTotalBalance(_vm.contact.Leases))),_c('br'),_vm._v(" Lifetime Value: "+_vm._s(_vm._f("formatMoney")(_vm.calLifetimePayment(_vm.contact.Leases)))),_c('br'),_vm._v(" Prepaid Balance: "+_vm._s(_vm._f("formatMoney")(_vm.totalPrepaidBalance(_vm.contact.Leases)))),_c('br')]},proxy:true}:null,(_vm.calculateLength(_vm.contact.Leases, 'sameProperties') > 0)?{key:"menuSpaces",fn:function(){return _vm._l((_vm.contact.Leases),function(lease){return _c('div',[(_vm.filterLeases(lease.Unit?.property_id) && !lease.end_date)?_c('HbMiniProfileItem',{attrs:{"type":"space","space-type":lease.Unit?.type,"space-category":lease.Unit?.label,"space-number":lease.Unit?.number,"space-property-info":lease.Unit?.property_number + ' - ' + _vm.spaceAddressInfo(lease.Unit?.Address),"space-status":lease?.Standing?.name,"space-lockout":_vm.spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.hard_catch ===
              1 ||
            _vm.spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.late_catch ===
              1 ||
            _vm.spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.status === 0
              ? true
              : false,"space-access-code":_vm.accessAPIDone
              ? _vm.spaceAccessCode(lease.Unit?.property_id, lease.Unit?.id)?.pin
              : '',"disable-space-link":""}}):_vm._e()],1)})},proxy:true}:null,(_vm.calculateLength(_vm.contact.Leases, 'otherProperties') > 0)?{key:"menuSpacesOtherProperties",fn:function(){return _vm._l((_vm.contact.Leases),function(lease){return _c('div',[(_vm.filterLeases(lease.Unit?.property_id) === false && !lease.end_date)?_c('HbMiniProfileItem',{attrs:{"type":"space","space-type":lease.Unit?.type,"space-number":lease.Unit?.number,"space-category":lease.Unit?.label,"space-status":lease.Standing?.name,"space-property-info":lease.Unit?.property_number + ' - ' + _vm.spaceAddressInfo(lease.Unit?.Address),"disable-space-link":""}}):_vm._e()],1)})},proxy:true}:null,(_vm.calTotalLeads(_vm.contact) > 0)?{key:"menuActiveLeads",fn:function(){return [(_vm.contact.Reservations?.length > 0)?_c('div',_vm._l((_vm.contact.Reservations),function(reservation){return _c('div',[_c('HbMiniProfileItem',{attrs:{"type":"lead","lead-type":_vm.reservationInfo(reservation)?.type,"lead-space-type":_vm.reservationInfo(reservation).spaceType,"lead-space-number":_vm.reservationInfo(reservation).spaceNumber,"lead-space-category":_vm.reservationInfo(reservation).spaceLabel,"lead-property-info":_vm.reservationInfo(reservation).spaceAddress,"disable-lead-link":""}})],1)}),0):_vm._e(),(_vm.contact.Pending?.length > 0)?_c('div',_vm._l((_vm.contact.Pending),function(pending){return _c('div',[_c('HbMiniProfileItem',{attrs:{"type":"lead","lead-type":_vm.pendingInfo(pending)?.type,"lead-space-type":_vm.pendingInfo(pending).spaceType,"lead-space-number":_vm.pendingInfo(pending).spaceNumber,"lead-space-category":_vm.pendingInfo(pending).spaceLabel,"lead-property-info":_vm.pendingInfo(pending).spaceAddress,"disable-lead-link":""}})],1)}),0):_vm._e(),_vm._l((_vm.contact.Leads),function(lead){return _c('div',[(
            lead.id &&
            !_vm.checkForExistsLead(_vm.contact.Reservations, lead.Reservation?.id) &&
            !_vm.checkForExistsLead(_vm.contact.Leases, lead.Lease?.id) &&
            !_vm.checkForExistsLead(_vm.contact.Pending, lead.Lease?.id)
          )?_c('div',[_c('HbMiniProfileItem',{attrs:{"type":"lead","lead-type":_vm.multiLeadInfo(lead)?.type,"lead-space-type":_vm.multiLeadInfo(lead).spaceType,"lead-space-number":_vm.multiLeadInfo(lead).spaceNumber,"lead-space-category":_vm.multiLeadInfo(lead).spaceLabel,"lead-property-info":_vm.multiLeadInfo(lead).spaceAddress,"disable-lead-link":""}})],1):_vm._e()])})]},proxy:true}:null,(_vm.contact?.closedLeases?.length > 0)?{key:"menuClosedLeases",fn:function(){return _vm._l((_vm.contact?.closedLeases),function(closedLease){return _c('div',[_c('HbMiniProfileItem',{attrs:{"type":"closed","closed-space-type":closedLease.type || 'storage',"closed-count":closedLease.count}})],1)})},proxy:true}:null],null,true)}):_vm._e(),_c('hb-modal',{staticClass:"ma-0 py-0 interaction-window",attrs:{"size":"large","title":"Send Text","show-help-link":"","footer-off":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.showTextModal)?_c('send-sms',{attrs:{"to":_vm.contact.Phones && _vm.contact.Phones.length ? _vm.contact.Phones[0].phone : '',"interactiveMode":true,"contactId":_vm.contact.id,"onClose":_vm.closeText,"onChatError":_vm.onChatError,"spaceListViewOptions":_vm.unitOptions,"contactPhoneList":_vm.contactPhoneList,"phoneId":_vm.contact.Phones && _vm.contact.Phones.length ? _vm.contact.Phones[0]?.id : '',"textarea_height":200,"property_id":_vm.outBoundCallPropertyId,"properties":_vm.selectedProperties}}):_vm._e()]},proxy:true}]),model:{value:(_vm.showTextModal),callback:function ($$v) {_vm.showTextModal=$$v},expression:"showTextModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }