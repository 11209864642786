<template>
    <div class="bg-themed" :class="{ 'pr-3' : $vuetify.breakpoint.width > 480}">
        <div class="access-section slide-out-section py-6 pr-0 pl-1" :class="{'pr-6' : showAside && $vuetify.breakpoint.width > 1259, 'py-7 mx-6' : fromContactModal}">
            <hb-data-table
                :headers="isSpaceAccessCodesVendorHeaders" 
                :items="spaceAccess"
                :loading="loading || spaceAccessLoading"
                @click:row="startEditing"
                :mobile-breakpoint="320"
                v-if="computedIsSpaceAccessCodesVendor"
            >
                <template v-slot:item.property="{ item }">
                    {{ item.Property.Address.address }}
                    <span v-if="item.end_date"> - {{item.end_date | formatLocalShortDate}}</span>
                </template>
                <template v-slot:item.space="{ item }">
                    <div class="hb-input-overflow d-flex align-center">
                        <hb-unit-icon small color="#101318" class="pr-1" :type="getUnitTypeLabel(item)" />
                       {{ item.unit_number }}
                    </div>
                </template>
                <template v-slot:item.soft_catch="{ item }">
                    <hb-status-general v-if="item.soft_catch === 1 && item.hard_catch !== 1" status="Access Alert"></hb-status-general>
                </template>
                <template v-slot:item.status="{ item }">
                    <hb-status-general v-if="item.hard_catch === 1" status="Manual Suspension"></hb-status-general>
                    <hb-status-general v-else-if="item.late_catch === 1" status="Delinquency Suspension"></hb-status-general>
                    <hb-status-general v-else status="Active"></hb-status-general>
                </template>
                <template v-slot:item.last_updated="{ item }">
                    <div class="my-2">
                        <div class="hb-text-night hb-font-caption">{{item.modified_by_fullname}}</div>
                        <div class="hb-text-light hb-font-caption">{{ editHourTime(item.modified_at) }}</div>
                    </div>
                </template>
                <template v-slot:item.menu="{ item }">
                    <hb-menu
                        options
                        align-right
                    >

                        <v-list>
                            <v-list-item @click.stop="startEditing(item)" :disabled="loading">
                                <v-list-item-title>Edit Access Code</v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item :disabled="loading || item.hard_catch === 1" @click.stop="toggleSoftCatch(item)">
                                <v-list-item-title>{{ item.soft_catch ? 'Remove ' : 'Enable' }} Access Alert</v-list-item-title>
                            </v-list-item>

                            <v-list-item :disabled="loading" @click.stop="toggleHardCatch(item)">
                                <v-list-item-title>{{ item.hard_catch ? 'Remove ' : 'Enable' }} Manual Suspension</v-list-item-title>
                            </v-list-item>
                        </v-list>

                    </hb-menu>
                </template>
            </hb-data-table>
            
            <hb-data-table
                :headers="headers"
                :items="access"
                :loading="loading"
                @click:row="startEditing"
                :mobile-breakpoint="320"
                v-else
            >
                <template v-slot:item.property="{ item }">
                    {{ item.Property.Address.address }}
                    <span v-if="item.end_date"> - {{item.end_date | formatLocalShortDate}}</span>
                </template>
                <template v-slot:item.space="{ item }">
                    <HbSpaceIcon small :type="item.unit_type || 'default'" color="#101318"  />
                    {{ item.unit_number }}
                </template>
                <template v-slot:item.access="{ item }">
                    {{ item.pin }}
                </template>
                <template v-slot:item.status="{ item }">
                    <span v-if="item.access_id !== null && item.status !== null">
                        <hb-status-general :status="get_status(item.status)"></hb-status-general>
                    </span>
                    <span v-else>
                        <hb-status-general type="caution" status="Not Configured"></hb-status-general>
                    </span>
                </template>
                <!--  DO NOT DELETE FOR ADDITIONAL CONTACTS
                    <template v-slot:item.additional_contacts="{ item }">
                    <hb-tooltip>
                        <template v-slot:item>
                        </template>
                        <template v-slot:header>
                        </template>
                        <template v-slot:body>
                        </template>
                    </hb-tooltip>
                </template>
                -->
                <template v-slot:item.last_updated="{ item }">
                    <div class="my-2">
                        <div class="hb-text-night hb-font-caption">{{item.modified_by_fullname}}</div>
                        <div class="hb-text-light hb-font-caption">{{ editHourTime(item.modified_at) }}</div>
                    </div>
                </template>
                <template v-slot:item.menu="{ item }">
                    <hb-menu
                        options
                        align-right
                        :disabled="item.access_id == null"
                    >

                        <v-list>
                            <v-list-item @click.stop="startEditing(item)" :disabled="loading || suspendActivateInProgress">
                                <v-list-item-title>Edit Access Code</v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item v-if="isAdmin && item.status !== 0" :disabled="loading || suspendActivateInProgress" @click.stop="suspendUser(item)">
                                <v-list-item-title>Suspend User</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="isAdmin && item.status !== 1" :disabled="loading || suspendActivateInProgress" @click.stop="activateUser(item)">
                                <v-list-item-title>Activate User</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="upgradedProperties[item.property_id] && item.status !== 0" :disabled="loading || suspendActivateInProgress" @click.stop="startChangingHours(item)">
                                <v-list-item-title>Change Access Hours</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="upgradedProperties[item.property_id] && item.status !== 0 && !!item.unit_id" :disabled="loading || suspendActivateInProgress" @click.stop="startChangingAreas(item)">
                                <v-list-item-title>Change Access Area</v-list-item-title>
                            </v-list-item>
                        </v-list>

                    </hb-menu>
                </template>
            </hb-data-table>

            <hb-modal v-model="showAdd" size="medium" title="Access Control" show-help-link @close="stopEditing()">
                <template v-slot:subheader>
                    Add / Edit Access Code
                </template>
                <template v-slot:content>
                    <hb-form label="Code" required :divider="false">
                        <v-row no-gutters>
                            <v-col>
                                <hb-text-field
                                    v-if="computedIsSpaceAccessCodesVendor"
                                    v-model="selected.pin"
                                    v-validate="'required|min:4|max:4'"
                                    :rules="pinRules"
                                    placeholder="Enter Code"
                                    id="pin"
                                    name="pin"
                                    :error="errors.has('code.pin')"
                                    data-vv-as="Code"
                                    data-vv-scope="code"
                                    :disabled="savingCodeInProgress"
                                >
                                    <template v-slot:prepend>
                                        {{ pinFormattedUnitNumber }}
                                    </template>
                                </hb-text-field>
                                <hb-text-field
                                    v-else
                                    v-model="selected.pin"
                                    v-validate="validationRule"
                                    :rules="pinRules"
                                    placeholder="Enter Code"
                                    id="pin"
                                    name="pin"
                                    :error="errors.has('code.pin')"
                                    data-vv-as="Code"
                                    data-vv-scope="code"
                                >
                                </hb-text-field>
                            </v-col>
                            <v-col cols="1" class="mx-2">
                                <hb-btn icon @click="generateCode" tooltip="Generate Code" active-state-off :disabled="savingCodeInProgress">mdi-refresh</hb-btn>
                            </v-col>
                        </v-row>
                    </hb-form>

                    <!-- old way
                    <v-row class="ma-0 pa-0">
                        <v-col md="4" class="key-label pl-6 pt-5">
                            <div class="pt-2 font-15px">Code</div>
                        </v-col>
                        <v-col md="7" class="pa-5 pl-6 pr-2">
                            <div class="form-input-container">
                                <v-text-field
                                    hide-details
                                    label="Enter Code"
                                    single-line
                                    v-validate="validationRule"
                                    required
                                    :rules="pinRules"
                                    id="pin"
                                    name="pin"
                                    v-model="selected.pin"
                                    class="pa-0 mt-0 hide-space"
                                > </v-text-field>
                                <span v-show="errors.has('pin')" class="status-block error-block field-error">{{ errors.first('pin') }}</span>
                            </div>
                        </v-col>
                        <v-col md="1" class="py-0 pr-5 d-flex align-center justify-center">
                            <hb-btn icon @click="generateCode">mdi-refresh</hb-btn>
                        </v-col>
                    </v-row>
                    -->
                </template>
                <template v-slot:actions>
                    <hb-btn color="primary" :loading="savingCodeInProgress" :disabled="isLoading('accessCode') || savingCodeInProgress" @click="saveCode">Save</hb-btn>       
                </template>
            </hb-modal>

            <hb-modal
                title="Change Access Hours"    
                v-model="changeHoursVisible" 
                size="medium"
                content-padding
                @close="selectedHours = {}"
            >
                <template v-slot:subheader>
                    Change the hours of the selected space/Access Area.
                </template>
                <template v-slot:content>
                    <div class="hb-text-light hb-font-body mb-3">Select the access hours to apply to the space.</div>
                    <AccessHourSelection
                        ref="access-hour"
                        v-model="selectedHours"
                        :property-id="selectedPropertyId"
                        :area-id="selectedAreaId"
                        :contact-id="contact_id"
                        :change-in-progress="changeHoursInProgress"
                        :select-hour.sync="selectHour"
                    />
                </template>

                <template v-slot:right-actions>
                    <hb-btn 
                        color="primary" 
                        @click="applyAccessHours"
                        :disabled="applyAccessHoursInProgress || changeHoursInProgress"
                        :loading="applyAccessHoursInProgress || changeHoursInProgress"
                    >
                        Apply Access Hours
                    </hb-btn>       
                </template>
            </hb-modal>

            <hb-modal
                title="Change Access Area"    
                v-model="changeAreaVisible" 
                size="medium"
                content-padding
                @close="newAreaSelection = {}"
            >
                <template v-slot:subheader>
                    Select the Access Area you would like to apply to the selected space.
                </template>
                <template v-slot:content>
                    <HbSelect 
                        v-model="newAreaSelection"
                        :items="propertyAccessAreas"
                        item-text="displayName"
                        return-object
                        placeholder="Select Access Area"
                        :loading="fetchAreasInProgress"
                        :disabled="fetchAreasInProgress"
                    />
                </template>

                <template v-slot:right-actions>
                    <hb-btn 
                        color="primary" 
                        @click="applyAreaChange"
                        :disabled="fetchAreasInProgress || changeAreaInProgress"
                        :loading="fetchAreasInProgress || changeAreaInProgress"
                    >
                        Save
                    </hb-btn>       
                </template>
            </hb-modal>

            <ConfirmAccessHours
                v-model="confirmHoursVisible"
                :area-name="selectedAreaName"
                :hours-name="selectedHours.name"
                :units-in-area="formattedUnitsInArea"
                @confirm-change="confirmChange()"
                :change-in-progress="changeHoursInProgress"
            />

            <hb-modal 
                title="Confirm Access Area"    
                v-model="confirmAreaVisible" 
                size="medium"
                content-padding
                confirmation
            >
                <template v-slot:content>
                    <div class="hb-text-light hb-font-body">You are modifying the Access Area for the space {{ selectedAccessItem.unit_number }}</div>
                    <div class="hb-text-light hb-font-body-medium">{{ confirmAreaMessage }}</div>
                    <div class="hb-text-light hb-font-body">Are you sure you want to continue?</div>
                </template>
                <template v-slot:right-actions>
                    <hb-btn 
                        color="primary" 
                        @click="confirmAreaChange"
                        :disabled="changeAreaInProgress"
                        :loading="changeAreaInProgress"
                    >
                        Change
                    </hb-btn>       
                </template>
            </hb-modal>

        </div>
    </div>
    
</template>


<script type="text/babel">

    import Autocomplete from '../assets/Autocomplete.vue';
    import AccessHourSelection from '../access_hour_selection/AccessHourSelection.vue';
    import ConfirmAccessHours from '../access_hour_selection/ConfirmAccessHours.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import { tableKeys } from '../../mixins/tableColumns/contactAccess.js';
    import { mapGetters } from 'vuex';
    import { Validator } from 'vee-validate';

    Validator.extend('leadindZeros', {
        getMessage: field => "Leading 0's are not allowed for " + field + " field.",
        validate: (value) => {
            let regex = /^[1-9]\d*$/;
            return regex.test(value)
        }
    });
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
	
    export default{
        name: "GateAccess",
        data(){
            return {
                access_areas_enabled: false, 
                upgradedProperties: {},
                confirmAreaVisible: false,
                confirmAreaMessage: '',
                changeAreaVisible: false,
                newAreaSelection: {},
                propertyAccessAreas: [],
                fetchAreasInProgress: false,
                changeAreaInProgress: false,
                changeHoursVisible: false,
                confirmHoursVisible: false,
                selectedAreaId: '',
                selectedAreaName: '',
                selectedPropertyId: '',
                selectedHours: {},
                selectHour: '',
                applyAccessHoursInProgress: false,
                changeHoursInProgress: false,
                contact_id: '',
                access:[],
                spaceAccess: [],
                editing: false,
                pin_format: '8',
                default_pin_format: '8',
                pin_min: null,
                pin_max: null,
                default_pin_min: '3',
                default_pin_max: '12',
                selected: {
                    pin: '',
                },
                unitsInSelectedArea: [],
                showAdd: false,
                isSpaceAccessCodesVendorHeaders: [
                    { text: 'Property', value: 'property' },
                    { text: 'Space', value: 'space' },
                    { text: 'Access Code', value: 'pin' },
                    { text: 'Gate Access Alert', value: 'soft_catch' },
                    { text: 'Gate Status', value: 'status' },
                    { text: 'Last Updated', value: 'last_updated' },
                    { text: '', align: 'right', sortable: false, value: 'menu' }
                ],
                loading: false,
                spaceAccessLoading: false,
                suspendActivateInProgress: false,
                savingCodeInProgress: false,
                modalIsSpaceAccessCodesVendor: false,
                pinFormattedUnitNumber: '',
                selectedAccessItem: {},
                fetchAccessHoursInProgress: false,
                propertyAccessHours: []
            }
        },
        props: {
            contact: {
                type:Object
            },
            showAside: {
                type: Boolean
            },
            isSpaceAccessCodesVendor: {
                type: Boolean,
                default: false
            },
            fromContactModal: {
                type: Boolean,
                default: false
            }
        },
        components:{
            AccessHourSelection,
            ConfirmAccessHours,
            Datepicker,
            Autocomplete,
            Dropdown,
            Loader,
            Status
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
            }),
            validationRule(){
                if(this.selected.name === "PTI" || this.selected.name === "PTI Cloud" || this.selected.name === "Open Tech CIA"){
                    return 'required|numeric|leadindZeros|min:' + this.pin_min + '|max:' + this.pin_max;
                }
                else if(this.selected.name === "DoorKing"){
                    return 'required|numeric|min:' + this.pin_min + '|max:' + this.pin_max + '|max_value:65000';
                }else{
                    return 'required|numeric|min:' + this.pin_min + '|max:' + this.pin_max;
                }
            },
            pinRules: function(){
                if(this.computedIsSpaceAccessCodesVendor){
                    return [
                        v => v.length === 4
                    ]
                } else {
                    return [
                        v => v.length >= this.pin_min,
                        v => v.length <= this.pin_max
                    ]
                }
            },
            computedIsSpaceAccessCodesVendor(){
                if(this.isSpaceAccessCodesVendor || this.modalIsSpaceAccessCodesVendor){
                    return true;
                } else {
                    return false;
                }
            },
            headers(){
                if (this.access_areas_enabled) {
                    return [
                        { text: 'Property', value: 'property' },
                        { text: 'Space', value: 'space' },
                        { text: 'Access Area', value: 'area_name' },
                        { text: 'Access Hours', value: 'vendor_access_level_name' },
                        { text: 'Access Code', value: 'access' },
                        { text: 'Status', value: 'status' },
                        //{ text: 'Additional Contacts', value: 'additional_contacts' },
                        { text: 'Last Updated', value: 'last_updated' },
                        { text: '', align: 'right', sortable: false, value: 'menu' }
                    ]
                }
                return [
                    { text: 'Property', value: 'property' },
                    { text: 'Access Code', value: 'access' },
                    // { text: 'Access Schedule', value: 'access_hour_name' },
                    { text: 'Status', value: 'status' },
                    { text: 'Last Updated', value: 'last_updated' },
                    { text: '', align: 'right', sortable: false, value: 'menu' }
                ]
            },
            formattedUnitsInArea(){
                return this.unitsInSelectedArea.join(", ");
            }
        },
        async created(){
            
            if(this.contact.id){
                this.contact_id = this.contact.id
            } else if (this.$route.params.contact_id){
                this.contact_id = this.$route.params.contact_id;
            }

            if(this.fromContactModal && this.contact && this.contact.Leases && this.contact.Leases[0] && this.contact.Leases[0].Unit && this.contact.Leases[0].Unit.property_id){
                await this.modalCheckIsSpaceAccessCodesVendor(this.contact.Leases[0].Unit.property_id);
            }

            if(this.computedIsSpaceAccessCodesVendor){
                this.fetchSpaceAccessCodesData();
            } else {
                this.fetchData();
            }


        },

        methods:{
            findUnitsInSelectedArea() {
                let numUnits = 0;
                if (this.access && this.access.length) {
                    let accessList = this.access;
                    let unitsInAreaList = accessList.flatMap((element) => {
                        let unitNumber = [];
                        if (element.area_id == this.selectedAreaId) {
                            unitNumber = element.unit_number;
                        };
                        return unitNumber;          
                    });
                    this.unitsInSelectedArea = unitsInAreaList;
                    numUnits = unitsInAreaList.length;
                }
                return numUnits;
            },
            editHourTime(modified_at){
                if(modified_at) return this.$options.filters.formatLocalDateTimeServices(modified_at);
                return '';
            },
            async startChangingAreas(item){
                this.selectedAccessItem = item;
                this.selectedPropertyId = this.selectedAccessItem.property_id;
                this.selectedAreaId = this.selectedAccessItem.area_id;
                this.selectedAreaName = this.selectedAccessItem.area_name;

                this.changeAreaVisible = true;
                if (!this.selectedPropertyId) {
                    this.showMessageNotification({ type: 'error', description: 'No Property ID is set or Property ID is invalid.' });
                    return;
                } else {
                    this.fetchAreasInProgress = true;
                    try {
                        let accessAreas = await api.get(this, api.PROPERTIES + this.selectedPropertyId + "/areas?active=1");
                        this.propertyAccessAreas = accessAreas.filter((element) => {
                            if (element.hb_area_id && element.hb_area_id != this.selectedAreaId && 
                            element.name && element.default_vendor_access_level_name && element.active) {
                                element.displayName = element.name
                                return true;
                            } else {
                                return false;
                            }
                        })
                    } catch {
                        this.showMessageNotification(
                            { type: 'error', description: 'No Access Areas have been set in Hummingbird. Please configure Access Areas or contact the Customer Support team.' });
                    } finally {
                        this.fetchAreasInProgress = false;  
                    }
                }
            },
            applyAreaChange(){
                if (this.newAreaSelection?.hb_area_id) {
                    this.changeAreaVisible = false;
                    let accessInArea = this.access.find((element) => element.area_id === this.newAreaSelection.hb_area_id);
                    let newHours = '';
                    if (accessInArea) {
                        this.confirmAreaMessage = "The Access Hours will be set to the current hours of the Access Area";
                        newHours = accessInArea.vendor_access_level_name;
                    } else {
                        this.confirmAreaMessage = "The Access Hours will be set to the default hours for the Access Area";
                        newHours = this.newAreaSelection.default_vendor_access_level_name;
                    }
                    if (newHours) this.confirmAreaMessage = `${this.confirmAreaMessage} (${newHours})`;
                    this.confirmAreaVisible = true;
                } else {
                    this.showMessageNotification({ type: 'error', description: 'An Access Area selection is required.' });
                }
            },
            confirmAreaChange(){

                if (this.selectedAccessItem?.unit_id && this.newAreaSelection?.hb_area_id) {
                    let data = {
                        area_id: this.newAreaSelection?.hb_area_id
                    }
                    this.changeAreaInProgress = true;
                    api.put(this, api.UNITS + this.selectedAccessItem.unit_id + '/area', data).then(r => {
                        this.fetchData();
                        EventBus.$emit("access_pin_modified");
                        this.showMessageNotification({ type: 'success', description: 'Tenant Access Area updated successfully.'});
                    }).catch(err => {
                        this.showMessageNotification({ description: err });
                    }).finally(() => {
                        this.confirmAreaVisible = false;
                        this.changeAreaInProgress = false;
                    });
                } else {
                    this.showMessageNotification({ type: 'error', description: 'No Access Area or space is selected.' });
                }
            },
            startChangingHours(item){
                this.selectedAccessItem = item;
                this.selectedPropertyId = this.selectedAccessItem.property_id;
                this.selectedAreaId = this.selectedAccessItem.area_id;
                this.selectedAreaName = this.selectedAccessItem.area_name;
                if (!this.selectedPropertyId) {
                    this.showMessageNotification({ type: 'error', description: 'No Property ID is set or Property ID is invalid.' });
                    return;
                }
                if (!this.selectedAreaId || !this.selectedAreaName) {
                    this.showMessageNotification({ type: 'error', description: 'No Access Area is set or Access Area is invalid.' });
                    return;
                }
                this.selectHour = item.vendor_access_level_name;
                this.changeHoursVisible = true;
                if (this.$refs["access-hour"]) this.$refs["access-hour"].fetchAccessHours();
            },
            applyAccessHours(){
                if (!this.selectedHours?.vendor_access_level_id) {
                    this.showMessageNotification({ type: 'error', description: 'A valid Access Hour selection is required.'});
                } else {
                    this.applyAccessHoursInProgress = true;
                    let numUnits = this.findUnitsInSelectedArea();
                    if (numUnits > 1) {
                        this.changeHoursVisible = false;
                        this.confirmHoursVisible = true;
                        this.applyAccessHoursInProgress = false;
                    } else {
                        this.applyAccessHoursInProgress = false;
                        this.confirmChange();
                    }
                }
            },
            confirmChange(){
                var data = {
                    status: this.selectedAccessItem.status,
                    property_id: this.selectedAccessItem.property_id,
                    area_id: this.selectedAccessItem.area_id,
                    vendor_access_level_id: this.selectedHours.vendor_access_level_id
                };
                this.changeHoursInProgress = true;
                if(this.selectedAccessItem.id && this.selectedHours.vendor_access_level_id){
                    api.put(this, api.CONTACTS + this.contact_id + '/access/' + this.selectedAccessItem.id, data).then(r => {
                        this.fetchData();
                        this.confirmHoursVisible = false;
                        EventBus.$emit("access_pin_modified");
                        this.showMessageNotification({ type: 'success', description: 'Tenant access hours updated successfully.'});
                    }).catch(err => {                   
                        this.showMessageNotification({ type: 'error', description: err });
                    }).finally(() => {
                        this.changeHoursInProgress = false;
                        this.changeHoursVisible = false;
                    });
                } else {
                    this.showMessageNotification({ type: 'error', description: 'Invalid ID or selected new access hour is invalid.' });
                    this.changeHoursInProgress = false;
                    this.changeHoursVisible = false;
                }
                
            },
            slugify(text){
                return text.toString().toLowerCase()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '');            // Trim - from end of text
            },
            startEditing(data){
                this.editing = true;
                this.selected = JSON.parse(JSON.stringify(data));
                if(this.computedIsSpaceAccessCodesVendor && this.selected.unit_number){
                    var numString = this.selected.unit_number.toString();
                    var leadingZeros = "0000";
                    this.pinFormattedUnitNumber = leadingZeros.substring(0, leadingZeros.length - numString.length) + numString;

                    if(this.selected.pin.length === 8 && this.selected.pin.substr(0,4) == this.pinFormattedUnitNumber){
                        var parsedPin = this.selected.pin.substr(4);
                        this.selected.pin = parsedPin;
                    }
                }
                this.pin_format = this.selected.Access.pin_format || this.default_pin_format;
                this.pin_min = this.selected.Access.pin_min || this.default_pin_min;
                this.pin_max = this.selected.Access.pin_max || this.default_pin_max;
                this.showAdd = true;
            },
            stopEditing(){
                this.showAdd = false;
                this.editing = false;
                this.selected = {
                    pin: ''
                };
                this.pinFormattedUnitNumber = '';
                this.$validator.reset('code');
            },

            suspendUser(access){
                this.suspendActivateInProgress = true;
                var data = {
                    status: 0,
                    //pin: access.pin,
                    property_id: access.property_id
                }
                return api.put(this, api.CONTACTS + this.contact_id + '/access/' + access.id + '/status', data).then(r => {
                    this.showMessageNotification({ type: 'success', description: 'User has been suspended.' });
                    this.fetchData();
                    EventBus.$emit("access_pin_modified");
                }).catch(err => {
                    this.showMessageNotification({ description: err });
                }).finally(() => {
                    this.suspendActivateInProgress = false;
                });
            },
            activateUser(access){
                this.suspendActivateInProgress = true;
                var data = {
                    status: 1,
                    pin: this.selected.pin,
                    property_id: access.property_id
                }
                return api.put(this, api.CONTACTS + this.contact_id + '/access/' + access.id + '/status', data).then(r => {
                    this.showMessageNotification({ type: 'success', description: 'User has been activated.' });
                    this.fetchData();
                    EventBus.$emit("access_pin_modified");
                }).catch(err => {
                    this.showMessageNotification({ description: err });
                }).finally(() => {
                    this.suspendActivateInProgress = false;
                });
            },
        
            fetchData(){
                this.loading = true;
                return api.get(this, api.CONTACTS + this.contact_id + '/access').then(r => {
                    this.access = r.access;
                    this.upgradedProperties = {};
                    for (let property of r.upgradedProperties) {
                        this.upgradedProperties[property.property_id] = true;
                    }
                    if (Object.keys(this.upgradedProperties).length) {
                        this.access_areas_enabled = true;
                    }
                    this.loading = false;
                })
            },

            fetchSpaceAccessCodesData(){
                this.spaceAccessLoading = true;
                api.get(this, api.CONTACTS + this.contact_id + '/space-access').then(r => {
                    this.spaceAccess = r.access;
                    
                    this.spaceAccessLoading = false;
                }).catch(err => {
                    this.showMessageNotification({ description: err });

                    this.spaceAccessLoading = false;
                });
            },

            get_status(status){
                if(status === 1) return 'Active';
                if(status === 0) return 'Suspended';
                if(status && status.length) return status;
                return 'Not Configured';
            },
            async saveCode(){
                var _this = this;

                if(this.computedIsSpaceAccessCodesVendor){
                    var data = {
                        pin: this.selected.pin,
                        property_id: this.selected.Property.id,
                        unit_id: this.selected.unit_id,
                        unit_number: this.selected.unit_number,
                        area_id: this.selected.area_id
                    }

                    const status = await this.$validator.validateAll('code'); 
                    if(!status) return;

                    if(this.selected.contact_access_id){
                        this.savingCodeInProgress = true;

                        api.put(this, api.CONTACTS + this.contact_id + '/access/' + this.selected.contact_access_id, data).then(r => {
                            _this.showAdd = false;
                            _this.editing = false;

                            this.spaceAccess.find(i => i.unit_id === this.selected.unit_id).pin = this.pinFormattedUnitNumber + this.selected.pin;
                            this.showMessageNotification({ type: 'success', description: 'Access Code was successfully updated.' });
                            EventBus.$emit('space_access_modified');
                            EventBus.$emit("access_pin_modified");
                            this.savingCodeInProgress = false;
                        }).catch(err => {
                            this.showMessageNotification({ description: err });

                            this.savingCodeInProgress = false;
                        });
                    } else {
                        api.post(this, api.CONTACTS + this.contact_id + '/access', data).then(r => {
                            _this.showAdd = false;
                            _this.editing = false;

                            this.spaceAccess.find(i => i.unit_id === this.selected.unit_id).pin = this.selected.pin;
                            this.showMessageNotification({ type: 'success', description: 'Access Code was successfully updated.' });
                            EventBus.$emit('space_access_modified');
                            EventBus.$emit("access_pin_modified");
                            this.savingCodeInProgress = false;
                        }).catch(err => {
                            this.showMessageNotification({ description: err });

                            this.savingCodeInProgress = false;
                        });
                    }
                } else {
                    var data = {
                        status: this.selected.status,
                        pin: this.selected.pin,
                        property_id: this.selected.property_id,
                        access_hour_id: this.selected.access_hour_id,
                        area_id: this.selected.area_id
                    }

                    const status = await this.$validator.validateAll('code'); 

                    if(!status){
                        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                        return;
                    } 

                    if(this.selected.id){
                        return api.put(this, api.CONTACTS + this.contact_id + '/access/' + this.selected.id, data, 'accessCode').then(r => {
                            _this.showAdd = false;
                            _this.editing = false;
                            this.fetchData();
                            this.showMessageNotification({ type: 'success', description: 'Access Code was successfully updated.' });
                            EventBus.$emit("access_pin_modified");
                        }).catch(err => {
                            this.showMessageNotification({ description: err });

                            this.savingCodeInProgress = false;
                        });
                    } else {
                        return api.post(this, api.CONTACTS + this.contact_id + '/access/', data, 'accessCode').then(r => {
                            _this.showAdd = false;
                            _this.editing = false;
                            this.fetchData();
                            this.showMessageNotification({ type: 'success', description: 'Access Code was successfully updated.' });
                            EventBus.$emit("access_pin_modified");
                        }).catch(err => {
                            this.showMessageNotification({ description: err });

                            this.savingCodeInProgress = false;
                        });
                    }
                }
            },
            generateCode(){
                if(this.computedIsSpaceAccessCodesVendor && this.selected.Property.id){
                    this.savingCodeInProgress = true;
                    var data = {
                        unit_id: this.selected.unit_id,
                        unit_number: this.selected.unit_number,
                    }
                    api.get(this, api.PROPERTIES + this.selected.Property.id + '/generate-space-code', data, 'gate' ).then(r => {
                        this.selected.pin = r.code.substr(4);
                        this.savingCodeInProgress = false;
                    }).catch(err => {
                        this.showMessageNotification({ description: err });

                        this.savingCodeInProgress = false;
                    });
                } else if(!this.computedIsSpaceAccessCodesVendor) {
                    this.savingCodeInProgress = true;
                    api.get(this, api.PROPERTIES + this.selected.property_id + '/generate-access-code', null, 'gate' ).then(r => {
                        this.selected.pin = r.code;
                        this.savingCodeInProgress = false;
                    }).catch(err => {
                       this.showMessageNotification({ description: err });

                        this.savingCodeInProgress = false;
                    });
                }
            
            },
            deleteUser(access){
                var data = {
                    status: this.selected.status,
                    pin: this.selected.pin,
                    property_id: access.property_id
                }

                return api.delete(this, api.CONTACTS + this.contact.id + '/access/' + access.access_id + '/' + access.property_id).then(r => {
                    this.editing = false;
                    this.fetchData();
                })
            },
            async modalCheckIsSpaceAccessCodesVendor(property_id){
                if(property_id){
                    try {
                        let r = await api.get(this, api.PROPERTIES + property_id + '/access');

                        if(r.property_access_name === 'Derrels'){
                            this.modalIsSpaceAccessCodesVendor = true;
                        }
                    } catch (err){
                        // console.log(err);
                    }
                }
            },

            toggleSoftCatch(item){
                this.loading = true;

                var data = {
                    property_id: item.Property.id,
                    soft_catch: item.soft_catch === 1 ? 0 : 1
                }

                api.put(this, api.UNITS + item.unit_id + '/catches', data).then(r => {

                    this.spaceAccess.find(i => i.unit_id === item.unit_id).soft_catch = data.soft_catch;

                    let success = {
                        type: 'success',
                        description: data.soft_catch === 0 ? 'Access Alert was successfully removed.' : 'Access Alert was successfully added.',
                    }

                    EventBus.$emit('space_access_modified');
                    EventBus.$emit("access_pin_modified");
                    this.showMessageNotification({ type: 'success', description: data.soft_catch === 0 ? 'Access Alert was successfully removed.' : 'Access Alert was successfully added.' });


                    this.loading = false;
                }).catch(err => {
                    this.showMessageNotification({ description: err });

                    this.loading = false;
                });
                
            },

            toggleHardCatch(item){
                this.loading = true;

                var data = {
                    property_id: item.Property.id,
                    hard_catch: item.hard_catch === 1 ? 0 : 1
                }

                api.put(this, api.UNITS + item.unit_id + '/catches', data).then(r => {

                    this.spaceAccess.find(i => i.unit_id === item.unit_id).hard_catch = data.hard_catch;

                    if(item.hard_catch === 1){
                        this.spaceAccess.find(i => i.unit_id === item.unit_id).soft_catch = 0;
                    }

                    EventBus.$emit('space_access_modified');
                    EventBus.$emit("access_pin_modified");
                    this.showMessageNotification({ type: 'success', description: data.hard_catch === 0 ? 'Manual Suspension was successfully removed.' : 'Manual Suspension was successfully added.' });

                    this.loading = false;
                }).catch(err => {
                    this.showMessageNotification({ description: err });

                    this.loading = false;
                });
            }
        },
        watch: {
            contact(){
                if(!this.computedIsSpaceAccessCodesVendor){
                    this.fetchData();
                }
                this.stopEditing();
            },
            computedIsSpaceAccessCodesVendor(){
                if(this.computedIsSpaceAccessCodesVendor){
                    this.fetchSpaceAccessCodesData();
                } else {
                    this.fetchData();
                }
            },
            confirmHoursVisible(value){
                if (value === false) this.selectedHours = {};
            },
            confirmAreaVisible(value){
                if (value === false) {
                    this.newAreaSelection = {};
                    this.confirmAreaMessage = '';
                }
            }
        },
        mixins:[tableKeys, notificationMixin],

    }
</script>

<style scoped>
    button.w-button.secondary-btn.code-btn {
        padding: 8px 15px;
    }
    .bg-white{
        background-color: #fff;
    }
    .bg-themed{
        background-color: #F9FAFB;
    }
    .table-heading{
        font-size: 13px;
        color:rgba(0, 0, 0, 0.75) !important;
    }
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .border-bottom{
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-right-border{
        border-top: 1px solid #DFE3E8;
    }
    .no-top-border{
        border-top: none;
    }
    .access-section {
        max-height: 45vh;
        height: 45vh;
    }
</style>
