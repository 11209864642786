<template>
  <div class="pl-0 pt-1 position-relative" style="width: 100%;">
    <div v-if="editor" class="vue-tiptop-container" ref="tiptopContainer" style="border:1px solid; border-color: #EBEBEB;">
      <div ref="tiptopContainerIcon" class="d-flex align-center">
        <hb-menu v-if="isVisible('formate')" class="editor-toolbar-icon editor-heading-formate mr-3 ml-3">

          <template class="formate-item" v-slot:menu-activator>
            <div class="d-flex">
              {{ textStyle }}
              <span class="d-flex ml-2" v-html="formaterIcon('arrows')"></span>
            </div>
          </template>

          <v-list>
              <v-list-item 
              :toggle="editor.isActive('paragraph')"
              @click="editor.chain().focus().setParagraph().run(); setActiveTextFormat('Normal')"
              :ripple="false">
                  <v-list-item-title>Normal</v-list-item-title>
              </v-list-item>

              <v-list-item 
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run(); setActiveTextFormat('Heading H1')"
              :ripple="false">
                <v-list-item-title><h1>Heading H1</h1></v-list-item-title>
              </v-list-item>


              <v-list-item 
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run(); setActiveTextFormat('Heading H2')"
              :ripple="false">
                <v-list-item-title><h2>Heading H2</h2></v-list-item-title>
              </v-list-item>

              <v-list-item 
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run(); setActiveTextFormat('Heading H3')"
              :ripple="false">
                <v-list-item-title><h3>Heading H3</h3></v-list-item-title>
              </v-list-item>

              <v-list-item 
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run(); setActiveTextFormat('Heading H4')"
              :ripple="false">
                <v-list-item-title><h4>Heading H4</h4></v-list-item-title>
              </v-list-item>

              <v-list-item @click="editor.chain().focus().toggleHeading({ level: 5 }).run(); setActiveTextFormat('Heading H5')"
              :ripple="false">
                <v-list-item-title><h5>Heading H5</h5></v-list-item-title>
              </v-list-item>

              <v-list-item @click="editor.chain().focus().toggleHeading({ level: 6 }).run(); setActiveTextFormat('Heading H6')"
              :ripple="false">
                <v-list-item-title><h6>Heading H6</h6></v-list-item-title>
              </v-list-item>
          </v-list>

        </hb-menu>

        <span
          v-if="isVisible('bold')"
          :toggle="editor.isActive('bold')"
          @click="editor.chain().focus().toggleBold().run(); toggleBold()"
          :class="{ 'is-active': editor.isActive('bold') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('bold')"
        ></span>

        <span
          v-if="isVisible('bold')"
          :toggle="editor.isActive('italic')"
          @click="editor.chain().focus().toggleItalic().run(), toggleitalics()"
          :class="{ 'is-active': editor.isActive('italic') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('italic')"
        ></span>

        <span
          v-if="isIconVisible('underline') && isVisible('underline')"
          @click="editor.chain().focus().toggleUnderline().run(),  toggleUnderline()"
          :toggle="editor.isActive('underline')"
          :class="{ 'is-active': editor.isActive('underline') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('underline')"
        ></span>

        <span
          v-if="isIconVisible('s') && isVisible('s')"
          :toggle="editor.isActive('strike')"
          @click="editor.chain().focus().toggleStrike().run(), toggleStrike()"
          :class="{ 'is-active': editor.isActive('strike') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('s')"
        ></span>

        <span
          v-if="isIconVisible('last_divider')"
          class="editor-toolbar-icon divider-bar"
          v-html="formaterIcon('divider')"
        ></span>

        <hb-icon  
        v-if="isIconVisible('left') && isVisible('left')"
        @click="editor.chain().focus().setTextAlign('left').run()" 
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        :customSize="18"
        class="mr-3 ml-2 editor-toolbar-icon"
        :color="`#1d2025`">
          mdi-format-align-left
        </hb-icon>

        <hb-icon  
          v-if="isIconVisible('center') && isVisible('center')"
        @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        :customSize="18"
        class="mr-3 editor-toolbar-icon"
        :color="`#1d2025`">
          mdi-format-align-center
        </hb-icon>

        <hb-icon 
          v-if="isIconVisible('right') && isVisible('right')"
        @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        :customSize="18"
        class="mr-2 editor-toolbar-icon"
        :color="`#1d2025`">
          mdi-format-align-right
        </hb-icon>

        <span
          v-if="isIconVisible('mid_second_divider')"
          class="editor-toolbar-icon divider-bar"
          v-html="formaterIcon('divider')"
        ></span>

        <span
          v-if="isIconVisible('image') && isVisible('image')"
          @click="addImage"
          :class="{ 'is-active': editor.isActive('image') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('image')"
        ></span>


        <span
          v-if="isIconVisible('link') && isVisible('link')"
          @click="setLink" 
          :class="{ 'is-active': editor.isActive('link') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('link')"
        ></span>

        <span
          v-if="isIconVisible('mid_divider')"
          class="editor-toolbar-icon divider-bar"
          v-html="formaterIcon('divider')"
        ></span>

        <span
        v-if="isIconVisible('bullet') && isVisible('bullet')"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('bullet')"
        ></span>

        <span
        v-if="isIconVisible('number') && isVisible('number')"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('number')"
        ></span>

        <span
          v-if="isIconVisible('divider')"
          class="editor-toolbar-icon divider-bar"
          v-html="formaterIcon('divider')"
        ></span>

        <span
          v-if="isIconVisible('quote') && isVisible('quote')"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('quote')"
        ></span>
        
        <span
          v-if="isIconVisible('clear') && isVisible('clear')"
          @click="editor.chain().focus().unsetAllMarks().run()" 
          class="editor-toolbar-icon"
          v-html="formaterIcon('clear')"
        ></span>
    
        <span
          v-if="isIconVisible('code') && isVisible('code')"
          @click="editor.chain().focus().toggleCode().run()" 
          :class="{ 'is-active': editor.isActive('code') }"
          class="editor-toolbar-icon"
          v-html="formaterIcon('code')"
        ></span>
    
        <hb-menu v-if="responsiveIcon.length > 0 && isVisible('menu')" class="editor-menu mr-3 ml-0">

          <template class="formate-item" v-slot:menu-activator>
            <hb-icon  
              :color="`#1d2025`">
              mdi-dots-vertical
            </hb-icon>
          </template>
          <v-list>
              <v-list-item 
                v-if="!isIconVisible('s') && isVisible('s')"
                @click="editor.chain().focus().toggleStrike().run(), toggleStrike()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('s')"
                  ></span> <span>Strikethrough</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('link') && isVisible('link')"
                @click="setLink"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('link')"
                  ></span> <span>Link</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('image') && isVisible('image')"
                @click="addImage"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('image')"
                  ></span> <span>Image</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('bullet') && isVisible('bullet')"
                @click="editor.chain().focus().toggleBulletList().run()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('bullet')"
                  ></span> <span>Bullet List</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('number') && isVisible('number')"
                @click="editor.chain().focus().toggleOrderedList().run()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('number')"
                  ></span> <span>Number List</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('quote') && isVisible('quote')"
                @click="editor.chain().focus().toggleBlockquote().run()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('quote')"
                  ></span> <span>Quote</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('clear') && isVisible('clear')"
                @click="editor.chain().focus().unsetAllMarks().run()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('clear')"
                  ></span> <span>Clear Format</span>  
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                v-if="!isIconVisible('code') && isVisible('code')"
                @click="editor.chain().focus().toggleCode().run()"
                :ripple="false">
                <v-list-item-title class="d-flex align-center"> 
                  <span
                    class="editor-toolbar-icon"
                    v-html="formaterIcon('code')"
                  ></span> <span>Code Format</span>  
                </v-list-item-title>
              </v-list-item>

          </v-list>

        </hb-menu>
      </div>

	</div>
  <div class="editor-container" ref="editorContainer" style="width: 100%">
    <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import Blockquote from "@tiptap/extension-blockquote"
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align'
import Dropcursor from '@tiptap/extension-dropcursor'
import { Image as TiptapImage } from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import { EventBus } from "../../EventBus.js";

const CustomImage = TiptapImage.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: 'auto',
        parseHTML: element => element.style.width || element.getAttribute('width'),
        renderHTML: attributes => {
          return {
            style: `width: ${attributes.width};`,
          }
        },
      },
    }
  },
})

export default {
  name: "RichTextEditor",
  data() {
    return {
      editor: null,
      textStyle: 'Normal',
      responsiveIcon: [],
      tiptopToolbarWidth: 0,
      menuIcon:  ['code', 'clear', 'quote', 'divider',  'number', 'bullet', 'mid_divider','link', 'image',  'mid_second_divider', 'right', 'center', 'left', 's'],
      includeTags: ['formate', 'bold','italic', 'underline', 'left', 'center', 'right', 'code', 'clear', 'quote', 'number', 'bullet', 'image', 'link', 's', 'menu']
    };
  },
  components: {
    "editor-content": EditorContent,
  },
  props: ["value", "proseHeight", "readonly", "excludeTags"],
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
    proseHeight(value){
      this.createEditor()
    }
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.createEditor()
    EventBus.$on("HB:templatedata", this.setContentOnCursor);
    setTimeout(() => {
      this.tiptopToolbarWidth = this.$refs.tiptopContainerIcon.clientWidth;
      this.checkScreenSize();
    })
  },
  destroyed() {
    EventBus.$off("HB:templatedata", this.setContentOnCursor);
  },

  beforeDestroy() {
    this.editor.destroy();
    window.removeEventListener('resize', this.checkScreenSize);
  },

  methods: {
    createEditor() {
      // Recreate editor instance
        if (this.editor) this.editor.destroy();
        
          this.editor = new Editor({
          content: this.value || " ",
          editable: !this.readonly,
          editorProps: {
          attributes: {
            style: `height: ${this.proseHeight}; padding: 30px; overflow-y: auto;`,
          },
        },
        extensions: [StarterKit, Underline, Blockquote.configure({
          HTMLAttributes: {
            class: 'hb-side-line',
          },
        }),
      Link.configure({
            openOnClick: true,
            autolink: false,
            linkOnPaste: false,
            validate: href => /^https?:\/\//.test(href),
          }),
      Placeholder.configure({
            placeholder: 'Compose your Personal Message...',
            
          }),
      TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          CustomImage,
          Dropcursor,
      
      ],
        onUpdate: () => {
          this.$emit("input", this.editor.getHTML());
        },
      });
    },
    isIconVisible(val, toggle = false) {
      return !this.responsiveIcon.includes(val)
    },
    isVisible(val) {
      let exclude = this.excludeTags ? this.excludeTags : [];
      return this.includeTags.filter(item => !exclude.includes(item)).includes(val);
    },
    checkScreenSize() {
      //this.containerWidth = this.$refs.tiptopContainer.clientWidth; // Get the width
      this.responsiveIcon = [];
      let itemWidth = 34;
      let exclude = this.excludeTags ? this.excludeTags : [];
      if(this.$refs.tiptopContainer) {

        let availableWidth = (this.tiptopToolbarWidth+10) - this.$refs.tiptopContainer.clientWidth;
        
        let menuItemExtract = availableWidth > 0 ? (Math.ceil(availableWidth/itemWidth)+1) : 0;
        console.log("tiptopContainer", menuItemExtract, availableWidth, this.$refs.tiptopContainer.clientWidth, this.$refs.tiptopContainerIcon.clientWidth, );
        
        this.responsiveIcon = this.menuIcon.slice(0, menuItemExtract);
        this.responsiveIcon = this.responsiveIcon.filter(item => !exclude.includes(item));
      }
    },
    setActiveTextFormat(value) {
      this.textStyle = value
    },
    addImage() {
      const url = window.prompt('Image Remote URL', "https://placehold.jp/300x300.png")
      if (url) {
        this.editor.chain().focus().setImage({ src: url, width: '300px' }).run()
      }
    },
    setContentOnCursor(mytext){
      this.editor.chain().focus().insertContent(mytext).run()
    },
	  setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      let url = window.prompt('URL', previousUrl);

	  if (url.includes("https://") === false && url.includes("http://") === false) {
        url = "https://" + url
    	}

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    formaterIcon(icon) {
      switch(icon) {
        case 'bold':
          return `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.25 18.9168H14.3333V16.4168H17.25C17.5815 16.4168 17.8995 16.5485 18.1339 16.7829C18.3683 17.0174 18.5 17.3353 18.5 17.6668C18.5 17.9984 18.3683 18.3163 18.1339 18.5507C17.8995 18.7851 17.5815 18.9168 17.25 18.9168ZM14.3333 11.4168H16.8333C17.1649 11.4168 17.4828 11.5485 17.7172 11.7829C17.9516 12.0174 18.0833 12.3353 18.0833 12.6668C18.0833 12.9983 17.9516 13.3163 17.7172 13.5507C17.4828 13.7851 17.1649 13.9168 16.8333 13.9168H14.3333V11.4168ZM19 14.9918C19.8083 14.4252 20.375 13.5002 20.375 12.6668C20.375 10.7835 18.9167 9.3335 17.0417 9.3335H11.8333V21.0002H17.7C19.45 21.0002 20.7917 19.5835 20.7917 17.8418C20.7917 16.5752 20.075 15.4918 19 14.9918Z" fill="#101318"/>
            </svg>`
        case 'italic':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3333 10V12.5H16.175L13.325 19.1667H11V21.6667H17.6667V19.1667H15.825L18.675 12.5H21V10H14.3333Z" fill="#101318"/>
            </svg>`
        case 'underline':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 23H21.6667V21.3333H10V23ZM15.8333 19.6667C17.1594 19.6667 18.4312 19.1399 19.3689 18.2022C20.3066 17.2645 20.8333 15.9927 20.8333 14.6667V8H18.75V14.6667C18.75 15.4402 18.4427 16.1821 17.8957 16.7291C17.3487 17.276 16.6069 17.5833 15.8333 17.5833C15.0598 17.5833 14.3179 17.276 13.7709 16.7291C13.224 16.1821 12.9167 15.4402 12.9167 14.6667V8H10.8333V14.6667C10.8333 15.9927 11.3601 17.2645 12.2978 18.2022C13.2355 19.1399 14.5073 19.6667 15.8333 19.6667Z" fill="#101318"/>
            </svg>`
        case 'underline':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 23H21.6667V21.3333H10V23ZM15.8333 19.6667C17.1594 19.6667 18.4312 19.1399 19.3689 18.2022C20.3066 17.2645 20.8333 15.9927 20.8333 14.6667V8H18.75V14.6667C18.75 15.4402 18.4427 16.1821 17.8957 16.7291C17.3487 17.276 16.6069 17.5833 15.8333 17.5833C15.0598 17.5833 14.3179 17.276 13.7709 16.7291C13.224 16.1821 12.9167 15.4402 12.9167 14.6667V8H10.8333V14.6667C10.8333 15.9927 11.3601 17.2645 12.2978 18.2022C13.2355 19.1399 14.5073 19.6667 15.8333 19.6667Z" fill="#101318"/>
            </svg>`
        case 's': 
          return `
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.2083 13.4C12.2083 11.1417 14.375 10 16.7 10C18.0667 10 19.2 10.4083 19.95 11.0667C20.5917 11.6083 21.1667 12.5083 21.1667 13.7667H18.6583C18.6583 13.5083 18.6167 13.275 18.5333 13.0583C18.2917 12.3417 17.5333 11.9917 16.6583 11.9917C15.1083 11.9917 14.7083 12.8417 14.7083 13.4083C14.7083 13.8083 14.9167 14.1417 15.325 14.4167C15.6417 14.625 15.9667 14.8167 16.5 15H12.6583C12.4833 14.7167 12.2083 14.2583 12.2083 13.4ZM24 17.5V15.8333H9V17.5H17.0167C17.975 17.875 18.65 18.125 18.65 19.1417C18.65 19.975 17.975 20.5333 16.75 20.5333C15.4667 20.5333 14.3083 20.0833 14.3083 18.4417H11.8333C11.8333 18.9 11.9 19.3833 12.0333 19.7583C12.7083 21.6667 14.775 22.5083 16.7583 22.5083C18.65 22.5083 21.175 21.7667 21.175 19.1333C21.175 18.8833 21.1667 18.1667 20.775 17.5167H24V17.5Z" fill="#101318"/>
          </svg>`       
        case 'image':
          return `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 7H9C7.9 7 7 7.9 7 9V23C7 24.1 7.9 25 9 25H23C24.1 25 25 24.1 25 23V9C25 7.9 24.1 7 23 7ZM23 23H9V9H23V23ZM17.96 16.29L15.21 19.83L13.25 17.47L10.5 21H21.5L17.96 16.29Z" fill="#101318"/>
            </svg>`;
        case 'link':
          return `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6 15.9998C10.6 14.8598 11.5266 13.9332 12.6666 13.9332H15.3333V12.6665H12.6666C10.8266 12.6665 9.33331 14.1598 9.33331 15.9998C9.33331 17.8398 10.8266 19.3332 12.6666 19.3332H15.3333V18.0665H12.6666C11.5266 18.0665 10.6 17.1398 10.6 15.9998ZM13.3333 16.6665H18.6666V15.3332H13.3333V16.6665ZM19.3333 12.6665H16.6666V13.9332H19.3333C20.4733 13.9332 21.4 14.8598 21.4 15.9998C21.4 17.1398 20.4733 18.0665 19.3333 18.0665H16.6666V19.3332H19.3333C21.1733 19.3332 22.6666 17.8398 22.6666 15.9998C22.6666 14.1598 21.1733 12.6665 19.3333 12.6665Z" fill="#101318"/>
            </svg>`;
        case 'bullet':
          return `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.25 15C8.55833 15 8 15.5583 8 16.25C8 16.9417 8.55833 17.5 9.25 17.5C9.94167 17.5 10.5 16.9417 10.5 16.25C10.5 15.5583 9.94167 15 9.25 15ZM9.25 10C8.55833 10 8 10.5583 8 11.25C8 11.9417 8.55833 12.5 9.25 12.5C9.94167 12.5 10.5 11.9417 10.5 11.25C10.5 10.5583 9.94167 10 9.25 10ZM9.25 20C8.55833 20 8 20.5667 8 21.25C8 21.9333 8.56667 22.5 9.25 22.5C9.93333 22.5 10.5 21.9333 10.5 21.25C10.5 20.5667 9.94167 20 9.25 20ZM11.75 22.0833H23.4167V20.4167H11.75V22.0833ZM11.75 17.0833H23.4167V15.4167H11.75V17.0833ZM11.75 10.4167V12.0833H23.4167V10.4167H11.75Z" fill="#101318"/>
            </svg>`;
        case 'number':
          return `
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 19.8333H9.66667V20.25H8.83333V21.0833H9.66667V21.5H8V22.3333H10.5V19H8V19.8333ZM8.83333 12.3333H9.66667V9H8V9.83333H8.83333V12.3333ZM8 14.8333H9.5L8 16.5833V17.3333H10.5V16.5H9L10.5 14.75V14H8V14.8333ZM12.1667 9.83333V11.5H23.8333V9.83333H12.1667ZM12.1667 21.5H23.8333V19.8333H12.1667V21.5ZM12.1667 16.5H23.8333V14.8333H12.1667V16.5Z" fill="#101318"/>
            </svg>`;
        case 'code':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.425 8L18.0583 8.33333L14.9417 23L13.3083 22.6667L16.425 8ZM22.0083 15.5L19.0167 12.5083V10.15L24.3667 15.5L19.0167 20.8417V18.4833L22.0083 15.5ZM7 15.5L12.35 10.15V12.5083L9.35833 15.5L12.35 18.4833V20.8417L7 15.5Z" fill="#101318"/>
            </svg>`
        case 'quote':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19.3332H14L15.3333 16.6665V12.6665H11.3333V16.6665H13.3333L12 19.3332ZM17.3333 19.3332H19.3333L20.6666 16.6665V12.6665H16.6666V16.6665H18.6666L17.3333 19.3332Z" fill="#101318"/>
            </svg>`;
        case 'clear':
          return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0583 9L9 10.0583L14.8083 15.8667L12.75 20.6667H15.25L16.5583 17.6167L21.275 22.3333L22.3333 21.275L10.2917 9.225L10.0583 9ZM12.3333 9V9.15L14.6833 11.5H16.6833L16.0833 12.9L17.8333 14.65L19.175 11.5H24V9H12.3333Z" fill="#101318"/>
            </svg>`
        case 'arrows': 
          return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5425 5.77124L7.77124 2L4 5.77124H11.5425Z" fill="#101318"/>
            <path d="M11.5425 10L7.77124 13.7712L4 10H11.5425Z" fill="#101318"/>
            </svg>`
        case 'divider': 
          return `<svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="12" y="4" width="1" height="24" fill="#050038" fill-opacity="0.12"/>
          </svg>`
      }
    }
  }
};
</script>
<style scoped>
.editor-container {
  padding-bottom: 0px;
}
.vue-tiptop-container :last-child.divider-bar{
  display: none;
}
</style>
<style>
.editor-toolbar-icon {
  height: 100%;
  align-items: center;
  display: flex;
}
.ProseMirror:focus {
    outline: none;
}

.ProseMirror p {
  font-size: 14px;
  color: #000000;
}

.ProseMirror ul {
  list-style-type: disc !important;
}

.ProseMirror {
	height: var(--proseHeight);
}
.vue-tiptop-container .editor-heading-formate {
  display: flex;
  max-width: 91px;
}
.vue-tiptop-container {
  display: flex;
  align-items: center;
}
.hb-side-line{
  margin:0px 0px 0px 0.8ex;
  border-left:1px solid rgb(204,204,204);
  padding-left:1rem;
}
/* removed unwanted margin in editor */
.tiptap p {
  margin-bottom: 0 !important;
}
.vue-tiptop-container {
  background-color: #F9FAFB;
  margin: 10px;
  position: relative;
  width: auto;
  border-radius: 3px;
  border: unset !important;
  z-index: 1;
  top: 3px;
}
.editor-container {
  position: relative;
  z-index: 0;
  top: 0px;
}
.editor-container div[contenteditable="true"] {
  padding: 0px 10px 0px 15px !important;
  scrollbar-width: none;
}
.editor-toolbar-icon svg path {
    fill: #505050;
}
.editor-toolbar-icon.is-active svg path {
  fill: #000;
}
</style>