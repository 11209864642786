<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-task-wrapper"
        :class="{ 'hb-task-wrapper-preview' : preview, 'hb-task-wrapper-elevation': expanded }"
        @click="$emit('click', payload)"
        ref="hbTaskRef"
    >
        <div
            class="hb-task-background hb-task-content-wrapper px-3 pb-3"
            :class="{ 'hb-task-condensed-preview-set-max-height' : preview }"
        >
            <div class="hb-task-hot-task-triangle" v-if="hotTask && !isDisabled"></div>
            <div class="hb-task-hot-task-icon" v-if="hotTask && !isDisabled">
                <hb-tooltip :disabled="!hotTaskTooltipHeader && !hotTaskTooltipBody">
                    <template v-slot:item>
                        <HbIcon small color="#ffffff" mdi-code="mdi-custom-hot-task" />
                    </template>
                    <template v-if="hotTaskTooltipHeader" v-slot:header>
                        {{ hotTaskTooltipHeader }}
                    </template>
                    <template v-if="hotTaskTooltipBody" v-slot:body>
                        {{ hotTaskTooltipBody }}
                    </template>
                </hb-tooltip>
            </div>
            <v-row v-if="icon || title || time" no-gutters class="mt-1 hb-task-header">
                <v-col :cols="preview || $vuetify.breakpoint.smAndDown ? 7 : 9" no-gutters class="pr-2 hb-task-overflow-handler" style="position:relative;top:1.5px;">
                    <hb-icon small :color="setIconColor" :mdi-code="setIcon" :style="!disableIconPositionAdjustment && iconPositionAdjustment ? 'top:' + iconPositionAdjustment : ''" class="hb-task-main-icon"></hb-icon>
                    <span class="font-weight-medium pl-2" :style="'color:' + setTitleColor">{{ setTitle }}</span>
                </v-col>
                <v-col :cols="preview || $vuetify.breakpoint.smAndDown ? 5 : 3" no-gutters class="d-flex justify-end align-center">
                    <HbStatusGeneral v-if="status === 'complete'" chip type="success" status="complete" class="hb-task-status-general-item ml-1" />
                    <HbStatusGeneral v-else-if="status === 'in progress'" chip type="caution" status="In Progress" class="hb-task-status-general-item ml-1" />
                    <HbStatusGeneral v-else-if="status === 'dismissed'" chip type="default" status="dismissed" class="hb-task-status-general-item ml-1" />
                    <HbStatusGeneral v-else-if="time && status === 'overdue'" chip type="error" :status="time" class="hb-task-status-general-item ml-1" />
                    <hb-status-general v-else-if="$slots.timerGreen && status === 'timer green'" chip time icon-off type="success" class="hb-task-status-general-item ml-1"><slot name="timerGreen"></slot></hb-status-general>
                    <HbStatusGeneral v-else-if="timerGreenValue && status === 'timer green'" chip time icon-off type="success" :status="timerGreenValue" class="hb-task-status-general-item ml-1" />
                    <hb-status-general v-else-if="$slots.timerRed && status === 'timer red'" chip time icon-off type="error" class="hb-task-status-general-item ml-1"><slot name="timerRed"></slot></hb-status-general>
                    <HbStatusGeneral v-else-if="timerRedValue && status === 'timer red'" chip time icon-off type="error" :status="timerRedValue" class="hb-task-status-general-item ml-1" />
                    <span v-else-if="time" class="hb-task-text-night-light hb-task-font-caption mt-1 hb-task-overflow-handler" style="margin-right:2px;">
                        {{ time }}
                    </span>
                    <hb-tooltip v-if="dismissable">
                        <template v-slot:item>
                            <div style="position:relative;top:1px;right:-7px;margin-left:-5px;margin-bottom:-3px;">
                                <hb-icon @click.stop="$emit('dismiss', payload)">mdi-close</hb-icon>
                            </div>
                        </template>
                        <template v-slot:body>
                            Dismiss
                        </template>
                    </hb-tooltip>
                </v-col>
            </v-row>

            <v-row no-gutters class="hb-task-font-caption mt-1 hb-task-content">
                <v-col :cols="preview ? 12 : 11">
                    <div
                        class="hb-task-info-wrapper"
                        ref="hbTaskInfoRef"
                    >
                        <div
                            v-if="$slots.default"
                            class="hb-task-default-slot-overflow-handler-2"
                            :class="isDisabled ? 'hb-task-disabled-font-color' : 'hb-task-text-night-light'"
                            ref="hbTaskInfoSlotRef"
                        >
                            <slot></slot>
                        </div>
                    </div>
                </v-col>
                <v-col cols="1" v-if="!preview" align="right">
                    <div class="hb-task-expand-icon">
                        <hb-tooltip>
                            <template v-slot:item>
                                <HbIcon pointer @click.stop.prevent="toggleExpanded()" :mdi-code="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" :color="isDisabled && !expanded ? '#A5B4BF' : ''"  />
                            </template>
                            <template v-slot:body>
                                {{ expanded ? 'Collapse' : 'Expand' }}
                            </template>
                        </hb-tooltip>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div v-if="expanded" no-gutters class="hb-task-expanded-content-wrapper hb-task-font-caption hb-task-text-night-light pt-0 pb-3 px-3">
            <v-row v-if="!hasDetails" no-gutters class="pt-3">
                No Details Available
            </v-row>
            <span v-else>
                <v-row v-if="assignedTo" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Assigned To:
                    </div>
                    <div>
                        {{ assignedTo }}
                    </div>
                </v-row>
                <v-row v-if="property && type !== 'space'" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Property:
                    </div>
                    <div>
                        {{ property }}
                    </div>
                </v-row>
                <v-row v-if="createdBy" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Created by:
                    </div>
                    <div>
                        {{ createdBy }}
                        <span v-if="createdByTime"><br />{{ createdByTime }}</span>
                    </div>
                </v-row>
                <v-row v-if="completedBy" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Completed by:
                    </div>
                    <div>
                        {{ completedBy }}
                        <span v-if="completedByTime"><br />{{ completedByTime }}</span>
                    </div>
                </v-row>
                <v-row v-if="updatedBy" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Updated by:
                    </div>
                    <div>
                        {{ updatedBy }}
                        <span v-if="updatedByTime"><br />{{ updatedByTime }}</span>
                    </div>
                </v-row>
                <v-row v-if="attachments" no-gutters class="pt-3">
                    <div class="hb-task-expanded-content-label">
                        Attachments:
                    </div>
                    <div>
                        {{ attachments }}
                    </div>
                </v-row>
                <v-row v-if="buttonOne || buttonTwo || buttonThree" no-gutters class="pt-3">
                    <div v-if="buttonOne" class="mr-3" @click.stop="$emit('button-one-click', payload)">
                        <hb-btn small color="secondary" :loading="buttonOneLoading" :disabled="buttonOneLoading || buttonOneDisabled">{{ buttonOne }}</hb-btn>
                    </div>
                    <div v-if="buttonTwo" class="mr-3" @click.stop="$emit('button-two-click', payload)">
                        <hb-btn small color="secondary" :loading="buttonTwoLoading" :disabled="buttonTwoLoading || buttonTwoDisabled">{{ buttonTwo }}</hb-btn>
                    </div>
                    <div v-if="buttonThree" class="mr-3" @click.stop="$emit('button-three-click', payload)">
                        <hb-btn small color="secondary" :loading="buttonThreeLoading" :disabled="buttonThreeLoading || buttonThreeDisabled">{{ buttonThree }}</hb-btn>
                    </div>
                </v-row>
            </span>
        </div>
         
        <div
            class="hb-task-contact-info-wrapper hb-task-contact-info-border-radius"
            :class="contactInfoBackgroundClass"
        >

            <VDivider v-if="((type !== 'contact' && type !== 'space') || ((type === 'contact' || type === 'space') && isDisabled)) && !expanded" no-gutters />

            <v-row no-gutters class="px-3" :class="{ 'hb-task-contact-info-if-has-divider-fix' : ((type !== 'contact' && type !== 'space') || ((type === 'contact' || type === 'space') && isDisabled)) && !expanded }">
                <v-col cols="12" no-gutters class="hb-task-overflow-handler py-1 d-flex align-center" v-if="type === 'contact'">

                    <div v-if="spaceType" style="margin-top:3px;">
                        <HbSpaceIcon small :type="spaceType" :color="isDisabled ? '#A5B4BF' : '#101318'" :class="{ 'pr-1' : space }" enable-tooltip />
                    </div>

                    <div v-if="space" class="font-weight-medium pr-2" style="margin-top:3px;" :class="{ 'hb-task-disabled-font-color' : isDisabled }">
                        {{ space }}
                    </div>

                    <HbIcon v-if="contactIsBusiness" :color="isDisabled ? '#A5B4BF' : '#101318'" small mdi-code="mdi-domain" />
                    <HbIcon v-else :color="isDisabled ? '#A5B4BF' : '#101318'" small mdi-code="mdi-account" />

                    <div  :class="{ 'hb-task-disabled-font-color' : isDisabled }" class="font-weight-medium pl-2 hb-task-overflow-handler" style="margin-top:3px;">{{ contactName }}</div>
                    
                    <v-spacer></v-spacer>
                    
                    <HbStatus
                        v-if="!isDisabled"
                        class="pl-2"
                        @emitColor="contactInfoBackgroundColor = $event"
                        style="position:relative;top:1.5px;"
                        :status="contactStatus"
                        :pointer="preview"
                    />
                </v-col>
                <v-col cols="12" no-gutters class="hb-task-overflow-handler py-1 d-flex align-center" v-else-if="type === 'space'">

                    <div v-if="spaceType" style="margin-top:3px;">
                        <HbSpaceIcon small :type="spaceType" :color="isDisabled ? '#A5B4BF' : '#101318'" :class="{ 'pr-1' : space }" enable-tooltip />
                    </div>

                    <div v-if="space" class="font-weight-medium pr-2" style="margin-top:3px;" :class="{ 'hb-task-disabled-font-color' : isDisabled }">
                        {{ space }}
                    </div>

                    <HbIcon :color="isDisabled ? '#A5B4BF' : '#101318'" small mdi-code="mdi-facility-custom-2" style="position:relative;top:4.5px;" />

                    <div  :class="{ 'hb-task-disabled-font-color' : isDisabled }" class="font-weight-medium pl-2 hb-task-overflow-handler" style="margin-top:3px;">{{ property }}</div>

                </v-col>
                <v-col cols="12" no-gutters class="hb-task-overflow-handler py-1 d-flex align-center" v-else>
                    <div v-if="footerText" class="font-weight-medium" style="margin-top:3px;" :class="{ 'hb-task-disabled-font-color' : isDisabled }">
                        {{ footerText }}
                    </div>
                </v-col>
            </v-row>

        </div>

    </div>
    
</template>

<script type="text/babel">

import HbBtn from './HbBtn.vue';
import HbIcon from './HbIcon.vue';
import HbStatus from './HbStatus.vue';
import HbStatusGeneral from './HbStatusGeneral.vue';
import HbSpaceIcon from './HbSpaceIcon.vue';
import HbTooltip from './HbTooltip.vue';

export default {
    name:'HbTask',
    data(){
        return {
            contactInfoBackgroundColor: '#F4F6F8',
        }
    },
    components:{
        HbBtn,
        HbIcon,
        HbStatus,
        HbStatusGeneral,
        HbSpaceIcon,
        HbTooltip
    },
    computed: {
        setIcon() {
            var tempIcon = '';

            if(this.icon){
                tempIcon = this.icon;
            } else {
                tempIcon = 'mdi-settings'
            }

            this.$emit('update:icon', tempIcon);
            return tempIcon;
        },
        setIconColor() {
            if(this.isDisabled){
                return '#A5B4BF';
            }
            else if(this.iconColor){
                return this.iconColor;
            } else {
                return '#101318';
            }
        },
        setTitleColor() {
            if(this.isDisabled){
                return '#A5B4BF';
            } else if(this.titleColor){
                return this.titleColor;
            } else {
                return '#101318';
            }
        },
        setTitle() {
            var tempTitle = '';

            if(this.title){
                tempTitle = this.title;
            } else {
                tempTitle = 'Task';
            }

            this.$emit('update:title', tempTitle);
            return tempTitle;
        },
        contactInfoBackgroundClass() {
            if(this.isDisabled || (this.type !== 'contact' && this.type !== 'space')){
                return 'hb-task-background-white';
            } else if(this.type === 'space' || this.contactInfoBackgroundColor == '#919EAB') {
                return 'hb-task-background-cloud-light';
            } else if(this.contactInfoBackgroundColor == '#02AD0F'){
                return 'hb-task-background-success-shade';
            } else if(this.contactInfoBackgroundColor == '#02AD0F'){
                return 'hb-task-background-success-shade';
            } else if(this.contactInfoBackgroundColor == '#FFD600'){
                return 'hb-task-background-caution-shade';
            } else if(this.contactInfoBackgroundColor == '#FB4C4C'){
                return 'hb-task-background-error-shade';
            } else if(this.contactInfoBackgroundColor == '#3D8FFF') {
                return 'hb-task-background-guidance-shade';
            } else {
                return 'hb-task-background-white';
            }
        },
        payload() {
            return this.$props;
        },
        isDisabled() {
            if(this.status === 'complete' || this.status === 'dismissed'){
                return true;
            } else {
                return false;
            }
        },
        hasDetails() {
            if(this.assignedTo || this.createdBy || this.completedBy || this.updatedBy || this.attachments || this.buttonOne || this.buttonTwo || this.buttonThree || (this.property && this.type !== 'space')){
                return true;
            } else {
                return false;
            }
        }
    },  
    methods: {
        toggleExpanded(){
            if(this.expanded){
                this.$emit('update:expanded', false);
            } else {
                this.$emit('update:expanded', true);
            }
        },
    },
    props: {
        type: {
            type: String,
            default: undefined
        },
        status: {
            type: String,
            default: undefined
        },
        iconPositionAdjustment: {
            type: String,
            default: '-1.5px'
        },
        disableIconPositionAdjustment: {
            type: Boolean,
            default: false
        },
        hotTask: {
            type: Boolean,
            default: false
        },
        hotTaskTooltipHeader: {
            type: String,
            default: undefined
        },
        hotTaskTooltipBody: {
            type: String,
            default: 'Hot Task'
        },
        icon: {
            type: String,
            default: undefined
        },
        iconColor: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        titleColor: {
            type: String,
            default: undefined
        },
        preview: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: undefined
        },
        time: {
            type: String,
            default: undefined
        },
        timerGreenValue: {
            type: String,
            default: 'undefined'
        },
        timerRedValue: {
            type: String,
            default: 'undefined'
        },
        assignedToId: {
            type: String,
            default: 'undefined'
        },
        assignedTo: {
            type: String,
            default: 'undefined'
        },
        createdById: {
            type: String,
            default: 'undefined'
        },
        createdBy: {
            type: String,
            default: 'undefined'
        },
        createdByTime: {
            type: String,
            default: 'undefined'
        },
        completedById: {
            type: String,
            default: 'undefined'
        },
        completedBy: {
            type: String,
            default: 'undefined'
        },
        completedByTime: {
            type: String,
            default: 'undefined'
        },
        updatedById: {
            type: String,
            default: 'undefined'
        },
        updatedBy: {
            type: String,
            default: 'undefined'
        },
        updatedByTime: {
            type: String,
            default: 'undefined'
        },
        attachments: {
            type: String,
            default: 'undefined'
        },
        buttonOne: {
            type: String,
            default: undefined
        },
        buttonOneLoading: {
            type: Boolean,
            default: false
        },
        buttonOneDisabled: {
            type: Boolean,
            default: false
        },
        buttonTwo: {
            type: String,
            default: undefined
        },
        buttonTwoLoading: {
            type: Boolean,
            default: false
        },
        buttonTwoDisabled: {
            type: Boolean,
            default: false
        },
        buttonThree: {
            type: String,
            default: undefined
        },
        buttonThreeLoading: {
            type: Boolean,
            default: false
        },
        buttonThreeDisabled: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: String,
            default: undefined
        },
        contactName: {
            type: String,
            default: undefined
        },
        contactStatus: {
            type: String,
            default: undefined
        },
        contactIsBusiness: {
            type: Boolean,
            default: false
        },
        spaceId: {
            type: String,
            default: undefined
        },
        space: {
            type: String,
            default: undefined
        },
        spaceType: {
            type: String,
            default: undefined
        },
        propertyId: {
            type: String,
            default: undefined
        },
        property: {
            type: String,
            default: undefined
        },
        footerText: {
            type: String,
            default: 'General'
        },
    },
}
</script>

<style scoped>

.hb-task-wrapper {
    background: #ffffff;
    border: 1px solid #C4CDD5;
    border-radius: 4px;
    width: 100%;
    color: #101318;
    font-size: 14px;
    position: relative;
    cursor: pointer;
}

.hb-task-background {
    background: #ffffff;
    position: relative;
    z-index: 2;
}

.hb-task-wrapper-preview {
    max-width: 328px;
    cursor: pointer;
}

.hb-task-wrapper-elevation {
    box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-task-content-wrapper {
    border-top-left-radius: 3px;
    border-top-right-radius: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.hb-task-overflow-handler, .hb-task-overflow-handler .hb-task-header * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-task-default-slot-overflow-handler-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.hb-task-font-caption {
    font-size: 12px;
    line-height: 16px;
}

.hb-task-text-night {
    color: #101318;
}

.hb-task-text-night-light {
    color: #637381;
}

.hb-task-contact-info-wrapper {
    min-height: 36px;
}

.hb-task-contact-info-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.hb-task-background-white {
    background-color: #FFFFFF;
}

.hb-task-background-cloud-lighter {
    background-color: #F9FAFB;
}

.hb-task-background-cloud-light {
    background-color: #F4F6F8;
}

.hb-task-background-error-shade {
    background-color: #F8E5E1;
}

.hb-task-background-caution-shade {
    background-color: #FFF9DA;
}

.hb-task-background-success-shade {
    background-color: #E1FAE3;
}

.hb-task-background-guidance-shade {
    background-color: #E7F1FF;
}

.hb-task-expand-icon {
    position: relative;
    top: -2px;
    right: -4px;
    height: 16px;
}

.hb-task-expanded-content-wrapper {
    background: #F9FAFB;
}

.hb-task-condensed-preview-set-max-height {
    max-height: 90px;
}

.hb-task-main-icon {
    position: relative;
}

.hb-task-disabled-font-color {
    color: #A5B4BF;
}

.hb-task-status-general-item {
    position: relative;
    top: 3px;
}

.hb-task-expanded-content-label {
    width: 100px;
}

.hb-task-hot-task-triangle {
    width: 69.638px;
    height: 35.161px;
    transform: rotate(-45deg);
    position: absolute;
    left: -43px;
    top: -6px;
    background: #FB4C4C;
    z-index: 1;
}

.hb-task-hot-task-icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.hb-task-contact-info-if-has-divider-fix {
    position: relative;
    top: -1px;
}

</style>